import React from "react";
import Seo from "../../components/Seo";
import { graphql } from "gatsby";
import Layout from "../../layouts/index";
import n1 from "../../images/news1.jpg";
import NFooter from "../../components/nFooter";

const NewsOne = (props) => {
  return (
    <Layout bodyClass="page-news noteworthy">
      <Seo
        title="Empowering the Next Generation"
        description="iTelaSoft in its program to cascade its knowledge to the next
                  generation of IT professionals participated in an education
                  session for selected students at SLIIT."
      />

      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img news1-bnr">
          <img alt="Empowering the Next Generation" src={n1} />
        </div>
        <div className="container container-narrow intro-title noteworthy-title">
          <div className="row">
            <div className="col-12">
              <div className="date">EVENTS | APRIL 8, 2019</div>
              <h1>Empowering the next generation</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-4 pt-md-5 mb-6">
        <div className="row justify-content-start">
          <div className="col-12 col-md-12">
            <div className="content-body">
              <div className="content">
                <p>
                  iTelaSoft in its program to cascade its knowledge to the next
                  generation of IT professionals participated in an education
                  session for selected students at SLIIT.
                </p>
                <p>
                  The session spoke about innovative mobile development
                  platforms which are been used globally.
                </p>
                <p>And it was taken by experts from the iTelaSoft team.</p>
                <p>
                  The students who participated in a quiz session were also
                  rewarded with training programs on mobile technology
                  platforms.
                </p>
                <p>
                  iTelaSoft strives to help organizations take a leap in
                  technology, by making things simple.
                </p>
              </div>
            </div>
          </div>
        </div>
        <NFooter
          prv1="/noteworthy/itelasoft-night-2019"
          prv2="/noteworthy/itelasoft-night-2019"
          next1="/noteworthy/itelasoft-partners-mobitel-on-iot-devices"
          next2="/noteworthy/itelasoft-partners-mobitel-on-iot-devices"
          titlel="iTelaSoft Celebrating Global Success"
          titler="iTelaSoft Partners Mobitel on IoT Devices"
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "news1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default NewsOne;
